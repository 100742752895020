<template>
  <div class="common_sub_container">
    <h2>ENCODE POSTAL SERVICE JOBS</h2>
    <section>
      <div class="two_tabs">
        <a
          class="tab_buttons active"
        >ADD</a>
       <!--  <a
          @click="showTab2=true,showTab1=false"
          v-bind:class="{ active: showTab2 }"
          class="tab_buttons"
        >VIEW</a> -->
      </div>
      <PostalTransactionSummaryAdd />
      <!-- <PostalTransactionSummaryView v-if="showTab2" /> -->
    </section>
  </div>
</template>
<script>
import PostalTransactionSummaryAdd from "./PostalTransactionSummary_Add.vue";
// import PostalTransactionSummaryView from "./PostalTransactionSummary_View.vue";
export default {
  name: "x",
  components: {
    PostalTransactionSummaryAdd,
    // PostalTransactionSummaryView
  },
  created(){
    this.$emit("breadCrumbData",["Encode Postal Summary"]);
  },
  data() {
    return {
      showTab1: true,
      showTab2: false
    };
  }
};
</script>
<style lang="scss"  scoped >
.two_tabs {
	padding: 40px 10px 0 10px;
	display: flex;
	justify-content: space-around;

	.tab_buttons {
		display: block;
		width: 40%;
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: #ececec;
		color: #333;
		border: 1px solid #ddd;

		span.label {
			display: none;
			// background: $theme-color;
			color: #000;
			padding: 5px 10px;
			border-radius: 4px;
		}
	}

	.tab_buttons.active {
		background: #004a7c;
		color: #fff;
	}
}
</style>